
export const Black = 'ExtraBold';
export const Bold = 'Bold';
export const Light = 'Light';
export const Regular = 'Regular';
export const Italic = 'Italic';
export const Medium = 'Medium';
export const Thin = 'Thin';

// we define available font weight and styles for each font here
const font = {
  Roboto: {
    weights: {
      ExtraBold: '900',
      Bold: '700',
      Medium: '500',
      Regular: '400',
      Light: '300',
      Thin: '100',
    },
    styles: {
      Italic: 'italic',
    },
  },
  'Noto Sans SC': {
    weights: {
      ExtraBold: '900',
      Bold: '700',
      Medium: '500',
      Regular: '400',
      Light: '300',
      Thin: '100',
    },
    styles: {
      Italic: 'italic',
    },
  },
};

// generate styles for a font with given weight and style
export const fontMaker = (weight = Regular, style = 'normal', family = 'Noto Sans SC') => {
  const { weights, styles } = font[family];

  return {
    fontFamily: family,
    fontWeight: weights[weight] || weights.Regular,
    fontStyle: styles[style] || 'normal',
  };
};
