import React, { Component } from 'react';
import RecoverLayout from './components/recover-password-layout';
import ChangePasswordServices from '../../services/index';
import {
  isMobile
} from "react-device-detect";

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { newPassword: '', loadingPage: true, redirect: false, rePassword: '', showNewPassword: false, showRePassword: false, token: '', showModal: false, codeError: '', loading: false, env: 'qa' };
  }
componentDidMount() {
  const { match } = this.props;
  const query = new URLSearchParams(this.props.location.search);
  const env = query.get('env');

  this.testToken(match.params.id, env ? env : 'qa').then(() => {

    this.setState({token: match.params.id, env: env ? env : 'qa' });
  try{
    if(isMobile) window.location = `custompro://app.customprotraining.com/recovery/${match.params.id}`;
  }catch(e){

  }
});
}

testToken = (token,env) => {
  return new Promise((resolve, reject) => {
    const obj = {
      "password": '',
      "token": token,
    }
    ChangePasswordServices(obj, env).then(() => {
      this.setState({loadingPage: false});
      resolve();
    }).catch((e) => {
      if(e.code === 'auth/recovery-token/invalid') {
        setTimeout(() => {
       this.setState({redirect: true, loadingPage: false});
      }, 1000);
        return reject();
      }
      this.setState({loadingPage: false});
      resolve();
    });
  });
}

changePassword = () => {
  const { newPassword, token, loading, env } = this.state;
  const obj = {
    "password": newPassword,
    "token": token,
  }
  if(token !== '' && !loading){
    this.setState({loading: true}, () => {
    ChangePasswordServices(obj, env).then(() => {
      this.clean();
      this.setState({showModal: true, codeError: '', loading: false})
    }).catch((e) => {
      if(e.code === 'auth/recovery-token/invalid') { 
       this.setState({redirect: true, loadingPage: false});
        return;
      }
      this.setState({showModal: true, codeError: e.code, loading: false})
    });
  });
  }
}
 clean(){
  this.setState({newPassword: '', rePassword: '', showNewPassword: false, showRePassword: false, showModal:false})
}
  handleChange = (name, value) => this.setState({ [name]: value }); 

  render() {
    return (
      <RecoverLayout clean={this.clean} changePassword={this.changePassword} {...this.state} setValue={this.handleChange} />
    );
  }
}
export default RecoverPassword;
