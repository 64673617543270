import React, { useState } from 'react';
import {
  Container, Row, Col, Button, Label, FormGroup, Input,
} from 'reactstrap';
import Loader from 'react-loader-spinner';
import { fontMaker } from '../../../../shared/widgets/typografy';
import '../style/index.css';
import Logo from '../../../../shared/assets/images/logo.png';
import LoaderComponent from '../../../../shared/widgets/loader';
import icPassword from '../../../../shared/assets/images/contraseña.png';
import icNotPassword from '../../../../shared/assets/images/ic_not_show.png';
import Header from '../../../../shared/assets/images/header.png';
import icEye from '../../../../shared/assets/images/eye.png';

import icCandado from '../../../../shared/assets/images/candado.png';
import icExclamacion from '../../../../shared/assets/images/exclamacion.png';

import { Redirect } from 'react-router-dom';

const styles = {
  center: {
    justifyContent: 'center',
    margin: 10,
  },
  textLeft: {
    textAlign: 'left', width: '100%', color: '#0064FB', fontSize: 14, ...fontMaker('Bold', 'normal'),

  },
  textMedium: {
    fontSize: 14, color: 'rgba(0,0,0,0.5)', ...fontMaker('Medium', 'normal'), width: '65%', textAlign: 'left', padding: 0, margin: 0,
  },
  textLight: {
    fontSize: 14, color: 'rgba(0,0,0,0.5)', ...fontMaker('Light', 'normal'), width: '65%', textAlign: 'left', padding: 0, margin: 0,
  },
  textModal: {
    fontSize: 16, color: '#13151E', ...fontMaker('Light', 'normal'), textAlign: 'center',
  },
  title: { fontSize: 18, color: '#7A7A7A', ...fontMaker('Regular', 'normal') },
  textLink: {
    fontSize: 14, color: '#0064FB', ...fontMaker('Light', 'normal'), width: '65%', textAlign: 'left', padding: 0, margin: 0,
  },
  responsiveImg: { width: '100%', height: '90%' },
  footer: { backgroundColor: '#F6F6F6', height: 200, marginTop: 100 },
  button: {
    color: 'white',
    backgroundColor: '#0064FB',
    ...fontMaker('Regular', 'normal'),
    fontSize: 12,
    borderRadius: 30,
    borderWidth: 0,
    height: 40,
    position: 'absolute',
    top: -20,
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: 270,
  },
  input: {
    backgroundColor: 'transparent',
    color: '#7A7A7A',
    fontSize: 12,
    borderRadius: 30,
    borderWidth: 0,
    height: 40,

  },
  ic: {
    height: 23,
    width: 25,
  },
  icHidden: {
    height: 18,
    width: 24,
  },
  textAlert: {
    textAlign: 'left',
    width: '100%',
    color: 'black',
    fontSize: 10,
    ...fontMaker('Regular', 'normal'),
    paddingTop: 15,
  },
  textAlertRed: {
    textAlign: 'left',
    color: '#EB5757',
    fontSize: 12,
    ...fontMaker('Bold', 'normal'),
    paddingRight: 10,
  },
  icExclamacion: {
    width: 25, height: 25,
  },
  icCandado: {
    width: 20, height: 20,
  },
};
function Layout({
  rePassword, newPassword, setValue, showNewPassword, showRePassword,
  changePassword, showModal, codeError, loading,
  redirect, loadingPage,
}) {
  const [blurNewPass, setBlurNew] = useState(false);
  const [blurRePass, setBlurRe] = useState(false);
  const [typeError, setTypeError] = useState('s');

  const check = (isConfirm) => {
    const strPassword = newPassword;
    if (newPassword === '' && !blurRePass && !isConfirm) return setTypeError('empty-new');
    if (!strPassword.match(/[a-z]/) && !isConfirm) return setTypeError('password-no-rule');
    if (!strPassword.match(/[A-Z]/) && !isConfirm) return setTypeError('password-no-rule');
    if (!strPassword.match(/[0-9]/) && !isConfirm) return setTypeError('password-no-rule');
    if (!strPassword.match(/.{6}/) && !isConfirm) return setTypeError('password-no-rule');
    if (strPassword.match(/[!@#$%^&*()_+=\[{\]};:<>|./?,-]/) && !isConfirm) return setTypeError('password-no-simbols');
    if (strPassword.match(/.{20}/) && !isConfirm) return setTypeError('password-max');
    if (newPassword === rePassword && newPassword !== '' && rePassword !== '' && isConfirm) {
      return setTypeError('');
    }
    if (rePassword === '' && isConfirm) return setTypeError('empty-re');
    if (newPassword !== rePassword) return setTypeError('no-match');
  };
  return (
    <Container
      fluid
      style={{
        backgroundColor: 'white', paddingTop: 1, margin: 0, padding: 0,
      }}
    >
      {redirect && <Redirect to="/expired" />}
      {loadingPage ? <LoaderComponent /> : (
        <Container fluid style={{ backgroundColor: 'white', paddingTop: 1 }}>
          <Row style={{ alignItems: 'center' }}>
            <Col><img src={Logo} /></Col>
            <Col>
              <Label style={styles.title}>
RECUPERAR CONTRASEÑA

              </Label>

            </Col>
          </Row>
          <Row>
            <Col style={{ margin: 0, padding: 0 }}><img style={styles.responsiveImg} src={Header} /></Col>
          </Row>
          <Row style={styles.center} form>
            <Col sm={3}>
              <FormGroup>
                <Label style={styles.textLeft}>Nueva contraseña</Label>
                <Col xs={11}>
                  <Row style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    borderRadius: 30,
                    borderWidth: 0,
                    border: blurNewPass ? '2px solid #0064FB' : '',
                    height: blurNewPass ? 45 : 40,
                    backgroundColor: blurNewPass ? 'white' : '#F6F6F6',
                  }}
                  >
                    <Col xs={1}>
                      <img src={icPassword} style={styles.ic} />
                    </Col>
                    <Col xs={10}>
                      <Input
                        style={styles.input}
                        className="input"
                        type={showNewPassword ? 'text' : 'password'}
                        value={newPassword}
                        onChange={(event) => {
                          setValue('newPassword', event.target.value);
                        }}
                        onBlur={() => {
                          setBlurNew(false);
                        }}
                        onFocus={() => {
                          setBlurNew(false);
                        }}
                        onKeyPress={() => check(false)}
                        name="password"
                        id="Ipassword"
                        placeholder="Ingrese la nueva contraseña"
                      />
                    </Col>
                  </Row>

                  <Col xs={1} style={{ position: 'absolute', right: -30, top: '15%' }}>
                    <a
                      style={{ padding: 0, backgroundColor: 'transparent', borderWidth: 0 }}
                      onClick={() => {
                        setValue('showNewPassword', !showNewPassword);
                      }}
                    >
                      <img src={showNewPassword ? icEye : icNotPassword} style={styles.icHidden} />
                    </a>
                  </Col>
                </Col>
                {!blurNewPass && (typeError === 'empty-new' || typeError === 'password-no-rule' || typeError === 'password-no-simbols' || typeError === 'password-max') && (
                <Label style={styles.textAlert}>
                  <Label style={styles.textAlertRed}>!</Label>
                  {typeError === 'empty-new' ? 'Este campo es requerido' : typeError === 'password-no-rule' ? 'Debe contener al menos 6 caracteres, incluyendo mayúscula, minúscula y números.' : typeError === 'password-no-simbols' ? 'La contraseña no debe contener carácter especial' : typeError === 'password-max' ? 'La contraseña no debe contener mas de 20 caracteres' : 'La contraseña de verificación no coincide.'}
                </Label>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row style={styles.center} form>
            <Col sm={3}>
              <FormGroup>
                <Label style={styles.textLeft}>Confirmar contraseña</Label>
                <Col xs={11}>
                  <Row style={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    borderRadius: 30,
                    outline: 'none',
                    borderColor: 'red',
                    border: blurRePass ? '2px solid #0064FB' : '',
                    height: blurRePass ? 45 : 40,
                    backgroundColor: blurRePass ? 'white' : '#F6F6F6',
                  }}
                  >
                    <Col xs={1}>
                      <img src={icPassword} style={styles.ic} />
                    </Col>
                    <Col xs={10}>
                      <Input
                        disabled={typeError === 'empty-new' || typeError === 'password-no-rule' || typeError === 'password-no-simbols' || typeError === 'password-max'}
                        style={styles.input}
                        className="input"
                        invalid={false}
                        value={rePassword}
                        type={showRePassword ? 'text' : 'password'}
                        onChange={(event) => {
                          setValue('rePassword', event.target.value);
                        }}
                        onBlur={() => {
                          setBlurRe(false);
                        }}
                        onFocus={() => {
                          setBlurRe(false);
                        }}
                        onKeyUp={() => check(true)}
                        name="re-password"
                        id="Irepassword"
                        placeholder="Ingrese nuevamente la contraseña"
                      />
                    </Col>
                  </Row>

                  <Col xs={1} style={{ position: 'absolute', right: -30, top: '15%' }}>
                    <a
                      style={{ padding: 0 }}
                      color="transparent"
                      onClick={() => {
                        setValue('showRePassword', !showRePassword);
                      }}
                    >
                      <img src={showRePassword ? icEye : icNotPassword} style={styles.icHidden} />
                    </a>
                  </Col>
                </Col>
                {(typeError === 'empty-re' || typeError === 'no-match') && (
                <Label style={styles.textAlert}>
                  <Label style={styles.textAlertRed}>!</Label>
                  {typeError === 'empty-re' ? 'Este campo es requerido' : 'La contraseña de verificación no coincide.'}
                </Label>
                )}
              </FormGroup>
            </Col>
            {showModal && (
            <div style={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, -42%)',
              backgroundColor: 'rgba(128, 128, 128, 0.58)',
              height: 320,
              width: '100%',
            }}
            >
              <div style={{
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, 0)',
                top: '15%',
                borderRadius: 20,
                backgroundColor: 'white',
                height: 211,
                width: 273,
              }}
              >
                <div className="end">
                  <div style={{
                    width: 30, height: 30, borderRadius: 50, margin: 10, justifyContent: 'center', alignItems: 'center', backgroundColor: codeError === '' ? '#0064FB' : '#EB5757',
                  }}
                  >
                    <img src={codeError === '' ? icCandado : icExclamacion} style={codeError !== '' ? styles.icExclamacion : styles.icCandado} />

                  </div>
                </div>
                <div style={{
                  position: 'absolute', top: '35%', marginLeft: 20, marginRight: 20,
                }}
                >
                  {codeError === '' ? <Label style={styles.textModal}>¡Tu contraseña ha sido modificada con éxito!</Label> : (<Label style={styles.textModal}>Ha ocurrido un error al cambiar su contraseña Por favor, inténtalo de nuevo.</Label>)}
                </div>
              </div>
            </div>
            )}
          </Row>
          <Row style={styles.footer} className="sticky-end">
            <Col sm={12}>
              <Button
                disabled={typeError !== ''}
                style={{ ...styles.button, backgroundColor: codeError === '' ? '#0064FB' : '#EB5757' }}
                onClick={() => {
                  if (showModal) { setValue('showModal', false); setValue('codeError', ''); } else { changePassword(); }
                }}
              >
                {loading && (
                <Loader
                  type="TailSpin"
                  color="white"
                  height={25}
                  width={25}
                />
                )}
                {!showModal && !loading && 'Guardar nueva contraseña'}
                {codeError === '' && showModal && !loading && 'Entendido'}
                {codeError !== '' && showModal && !loading && 'Reintentar'}
              </Button>
            </Col>
            <Col sm={12} style={{ marginTop: '5%' }}>
              <Row><Col><Label style={styles.textMedium}>¡ESFUÉRZATE!</Label></Col></Row>
              <Row><Col><Label style={styles.textLight}>NOSOTROS HACEMOS EL RESTO.</Label></Col></Row>
              <Row><Col><Label style={styles.textLight}>© 2017 CustomProTraining</Label></Col></Row>
              <Row><Col><Label style={styles.textLink}><a href="https://www.customprotraining.com">www.customprotraining.com</a></Label></Col></Row>
            </Col>
          </Row>

        </Container>
      )}
    </Container>
  );
}

export default Layout;
