export const BEARER_TOKEN_NAME = 'bearer_token_api';
export const BASE_API2 = 'https://api-qa.customprotraining.com';
// export const BASE_API = 'http://192.168.1.57:8080';
export const VERSION = 'v1';
export const BEARER_TOKEN = '';
export const HTTP_ERROR_INTERNET_LOST = 'http/no-internet';
export const HTTP_SERVER_UNAVAILABLE = 'http/server-unavailable';

class Api {
  async get(uri, env, version = VERSION) {
    const BASE_API = `https://api-${env}.customprotraining.com`;
    const url = `${BASE_API}${version ? `/${version}` : ''}/${uri}`;
    const headers = await this.getHeaders();
    return fetch(url, {
      method: 'GET',
      headers,
    }).then((response) => response.json());
  }

  async post(uri, body, env, version = VERSION, returnRequest = false) {
    const BASE_API = `https://api-${env}.customprotraining.com`;
    const headers = await this.getHeaders();
    try {
      const url = `${BASE_API}${version ? `/${version}` : ''}/${uri}`;
      const request = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      });
      if (returnRequest) return request;

      try {
        if (request.status === 500) return Promise.reject({ code: HTTP_SERVER_UNAVAILABLE });

        const response = await request.json();
        const { apierror } = response;
        if (apierror) {
          return Promise.reject(apierror);
        }
        return response;
      } catch (e) {
        return request;
      }
    } catch (e) {
      return Promise.reject({ code: HTTP_ERROR_INTERNET_LOST });
    }
  }

  async put(uri, body, env, version = VERSION, returnRequest = false) {
    const BASE_API = `https://api-${env}.customprotraining.com`;
    const headers = await this.getHeaders();
    try {
      const url = `${BASE_API}${version ? `/${version}` : ''}/${uri}`;
      const request = await fetch(url, {
        method: 'PUT',
        headers,
        body: JSON.stringify(body),
      });

      if (returnRequest) return request;

      try {
        if (request.status === 500) return Promise.reject({ code: HTTP_SERVER_UNAVAILABLE });

        const response = await request.json();
        const { apierror } = response;
        if (apierror) {
          return Promise.reject(apierror);
        }
        return response;
      } catch (e) {
        return request;
      }
    } catch (e) {
      return Promise.reject({ code: HTTP_ERROR_INTERNET_LOST });
    }
  }

  async delete(uri, env, version = VERSION) {
    const BASE_API = `https://api-${env}.customprotraining.com`;
    const url = `${BASE_API}${version ? `/${version}` : ''}/${uri}`;
    const headers = await this.getHeaders();
    return fetch(url, {
      method: 'DELETE',
      headers,
    });
  }

  setAuthorization(authorization) {

  }

  clearAuthorization() {
    try {
      // AsyncStorage.removeItem(BEARER_TOKEN_NAME);
    } catch (e) {
    }
  }

  getAuthorization() {
    try {
      // return AsyncStorage.getItem(BEARER_TOKEN_NAME);
    } catch (e) {
    }

    return null;
  }

  async getHeaders() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    /* const authorization = await this.getAuthorization();
    if (authorization !== null) {
      headers.Authorization = authorization;
    }*/

    return headers;
  }
}

export default new Api();
