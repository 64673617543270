import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import {
  Container, Row, Col,
} from 'reactstrap';

class LoaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { height, width } = this.state;
    return (
      <Container
        style={{
          backgroundColor: 'white', paddingTop: 1, height, width, justifyContent: 'center', alignItems: 'center',
        }}
      >
        <Row style={{ height, justifyContent: 'center', alignItems: 'center' }}>
          <Col
            sm={12}
            style={{
              justifyContent: 'center', alignItems: 'center',
            }}
          >
            <Loader
              type="TailSpin"
              color="black"
              height={150}
              width={150}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LoaderComponent;
