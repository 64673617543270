import React from 'react';
import ReactDOM from 'react-dom';
// new imports start
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import configureStore from './store';
// new imports stop
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';

import App from './App';
import RecoverPassword from './modules/auth/screens/recover-password/recover-password';
import ExpirePage from './modules/shared/widgets/expire-page';
import * as serviceWorker from './serviceWorker';

const routing = (
  <Provider store={configureStore()}>
    <Router>
      <div>
        <Route path="/recovery/:id" component={RecoverPassword} />

        <Route path="/expired" component={ExpirePage} />
        <Route exact path="/" component={App} />
      </div>
    </Router>
  </Provider>
);
// changed the render
ReactDOM.render(
  routing,
  document.getElementById('root'),
);
// changed the render

serviceWorker.unregister();
