import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import {
  Container, Row, Col, Label,
} from 'reactstrap';
import Logo from '../assets/images/logo.png';

class ExpirePage extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { height, width } = this.state;
    return (
      <Container
        fluid
        style={{
          backgroundColor: 'white', paddingTop: 1, height, width: width - 5, justifyContent: 'center', alignItems: 'center',
        }}
      >
        <Row style={{ position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
          <Col
            sm={12}
            style={{
              alignItems: 'center',
            }}
          >
            <Col><img src={Logo} /></Col>
          </Col>
        </Row>
        <Row style={{ height, justifyContent: 'center', alignItems: 'center' }}>
          <Col
            sm={12}
            style={{
              justifyContent: 'center', alignItems: 'center',
            }}
          >
            <Label style={{ fontSize: 22, fontWeight: 300 }}>La url expiro, por favor solicite una nueva</Label>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ExpirePage;
